@import 'variables.scss';

$color1: rgba(188, 16, 16, 0.679);
$color2: rgba(209, 28, 28, 0.796);
$color3: rgba(236, 109, 109, 0.07);
$color4: rgba(234, 46, 46, 0.637);
$color5: rgba(237, 114, 114, 0.414);
$color6: rgba(155, 10, 10, 0.877);

.top,
.diamond-slice-a,
.diamond-slice-b {
  transition: all .4s;
}

.intro {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.diamond {
  filter: brightness(1.08);
  width: 50vh;
  height: 50vh;
  max-width: 50vw;
  max-height: 50vw;
  perspective: 600vh;
   * {
    z-index: 10;
   }
   // Adjust .diamond for short viewports
   @media (max-height: 450px) {
      width: 100vh;
      height: 100vh;
      max-width: 80vh;
      max-height: 80vh;
   }
}


.diamond__base {
  position: relative;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  animation: linear ROTATE 25s infinite;
  background: radial-gradient(circle, rgba(234, 46, 46, 0.519) 0%, rgba(255, 50, 50, 0.2) 10%, transparent 50%);
}

.diamond-slice {
  position: absolute;
  width: 33.6%; 
  height: 50%;
  left: 32.4%;
  transform-origin: 50% 100%;
  transform-style: preserve-3d;
}

.diamond-slice-b {
  height: 40%;
  transform-origin: 50% 0;
  transform-style: preserve-3d;
  padding: 0 9.05%;
}

.facet {
   width: 100%;
   height: 100%;
   transform-origin: 50% 0;
   overflow: hidden;
}
  
.facet:before,
.facet:after {
   content: "";
   width: 50%;
   height: 100%;
   float: left;
   transform-origin: 0 0;
   background-color: $color1;
}
  
.facet:before {
   transform: skew(19deg);
}
  
.facet:after {
   transform: skew(-19deg);
}

.top {
  transform-origin: 50% 0;
  height: 100%;
  transform-style: preserve-3d;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    background-color: $color6;
    height: 100%;
    transform: rotate(67deg) skew(45deg) translateX(-18%) translateY(-69%);
    transform-origin: 50% 0;
  }
}

.diamond-bottom {
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
};
  
.diamond-slice-a {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    transform-origin: 50% 100%;
    transform-style: preserve-3d;
}

  .pavilion {
    margin-left: -116%;
    width: 333.33%;
    height: 200%;
    overflow: hidden;
    
  }

  .pavilion__face {
    width: 50%;
    height: 50%;
    background-color: $color4;
    transform: rotateZ(255deg) skew(59deg);
    transform-origin: 100% 100%;
    overflow: hidden;
    
    &:after {
      content: "";
      display: block;
      background-color: $color5;
      height: 100%;
      transform: rotate(73deg) skew(-23deg);
      transform-origin: 47% 123%;
      backface-visibility: hidden;
    }
  }

@for $i from 0 to 8 {
  .diamond-slice--#{$i} {
    transform: rotate(45deg * $i);
  }
  
  .diamond.intro .diamond-slice--#{$i} {
    .top,
    .diamond-slice-a,
    .diamond-slice-b {
      transition-delay: .2s * $i;
    }
    
    .top {
      transform: rotateX(30deg);
    }
  
    .diamond-slice-b {
      transform: rotateX(101deg);
    }
  
    .diamond-slice-a {
      transform: rotateX(48.2deg);
    }
  }
}

@keyframes ROTATE {
  0% {
    transform: rotateX(70deg) rotate(0); 
  }
  
  50% {
    transform: rotateX(75deg) rotate(360deg); 
  }

  100% {
    transform: rotateX(70deg) rotate(720deg); 
  }
}