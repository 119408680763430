$text-primary: #1c1111;
$text-secondary: #5d5757;
$text-accent: #f04343;
$text-accent2: #d20b0b;
$white: #fff;
$light-grey: #e3dede;

$link: #f8874f;

// Shadow colors
$shadow-light: rgba(62, 8, 20, 0.25);
$shadow-medium: rgba(62, 8, 36, 0.5);
$shadow: rgba(62, 8, 36, 0.65);
$shadow-dark: rgba(62, 8, 36, 0.7);

:export {
  textPrimary: $text-primary;
  textSecondary: $text-secondary;
  textAccent: $text-accent;
  textAccent2: $text-accent2;
  white: $white;
  lightGrey: $light-grey;
  link: $link;
  shadowLight: $shadow-light;
  shadowMedium: $shadow-medium;
  shadow: $shadow;
  shadowDark: $shadow-dark;
}
