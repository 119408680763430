// datePicker.scss
@import '../styles/variables';

.react-datepicker {
  border: none;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.1);
  
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: rgba(0, 0, 0, 0) ;
}

.react-datepicker__day--selected {
  background-color: $text-accent;
  
  &:hover {
    background-color: $text-accent2;
  }
}

.react-datepicker__input-container {
  input {
    font-size: 1.5em;
    font-weight: bold;
    color: $text-accent;
    text-decoration: underline;
    text-align: center;
    border: none;
    transition: color 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
      color: $text-accent2;
    }
    &:focus-visible {
      outline: none;
    }
  }
}

.react-datepicker__header {
  border: none;
}